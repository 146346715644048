.swiper-container {
	width: 100%;
	height: 100%;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
	bottom: -5px;
}
.swiper-pagination-bullet-active {
	background-color: #ffa726 !important;
	width: 9px;
	height: 9px;
}

.swiper-pagination-bullet {
	width: 9px;
	height: 9px;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
	background: #d4d4d6;
	opacity: 0.9;
}

.swiper-button-prev,
.swiper-button-next {
	position: absolute;
	top: 50%;
	width: 35px;
	height: 35px;
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background: orange;
	border: 0.5px solid orange;
	border-radius: 50%;
	transform: translateY(-50%);
	&:hover,
	&:focus {
		cursor: pointer;
		outline: none;
		box-shadow: 0 0 3px white;
	}
}
.swiper-button-prev {
	transform: translate(10px, -50%);
}

.swiper-button-next {
	transform: translate(-10px, -50%);
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
	left: 0;
	right: auto;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
	content: "\2039";
	font-size: 50px;
	color: white;
	padding-bottom: 15px;
	padding-right: 5px;
	font-weight: bold;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
	right: 0;
	left: auto;
}
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
	content: "\203A";
	font-size: 50px;
	color: white;
	padding-bottom: 15px;
	padding-left: 5px;
	font-weight: bold;
}

.swiper-button-lock {
	display: none;
	bottom: -5px;
}
.swiper-pagination-bullet-active {
	background-color: #ffa726;
}

.swiper-wrapper {
	padding-bottom: 40px;
}
